import React, { useEffect, useState, useRef } from "react";
import classes from "./reports.module.css";
import Navbar from "../Navbar/Navbar";
import camera from "../Images/camera.png";
import list from "../Images/list.png";
import grid from "../Images/grid.png";
import PdfIcons from "./PdfIcons";
import axios from "axios";
import pdficon from "../Images/pdficon.png";
import { useNavigate } from "react-router-dom";
import SignOut from "../SignOut/SignOut";
import dropdownIcon from "../Images/drop.png";
import searchbutton from "../Images/searchbutton.png";

const Reports = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [cont, setCont] = useState(false);
  const [totThatMember, settotThatMember] = useState(0);
  const [number, setnumber] = useState("");
  const [searched, setsearched] = useState(false);
  const [showdata, setshowdata] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [allendusers, setallendusers] = useState([]);
  const [allapollousers, setallapollousers] = useState([]);
  const [noalldata, setnoalldata] = useState(null);
  const [totenduser, settotenduser] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [name, setname] = useState("");
  var allKnownData = [];

  const searchInputRef = useRef(null);

  const accno = localStorage.getItem("accno");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setsearched(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const vitalsData = {
    name: "patient1",
    phoneNumber: "9073311543",
    email: "a@a.b",
    diabetic: "yes",
    activityFactor: 1,
    gender: "Male",
    age: "27",
    height: "180",
    weight: "70",
    allowable_blood_loss: "10",
    bmi: "25.5",
    body_fat: "18.3",
    body_water: "60.8",
    breathing_rate: "16",
    cardiac_index: "3.2",
    cardiac_output: "5.7",
    dbp: "80",
    hdl: "50",
    hba1c: "5.4",
    heart_rate: "72",
    hematocrit: "42",
    hemoglobin: "14.5",
    ibi: "800",
    ldl: "90",
    mean_atrial_pressure: "95",
    mean_corpuscular_hemoglobin: "32",
    mean_corpuscular_volume: "90",
    mean_plasma_glucose: "100",
    oxygen_saturation: "98",
    pulse_pressure: "40",
    pulse_rate_quotient: "1.8",
    rbc_count: "5.2",
    rmssd: "25",
    random_blood_sugar: "120",
    sbp: "120",
    sd1: "10",
    sd2: "20",
    sdnn: "40",
    stress_index: "70",
    stroke_volume: "70",
    subcutaneous_fat: "22.5",
    total_cholestrol: "180",
    triglycerides: "120",
    vldl: "24",
    visceral_fat: "15.2",
  };

  // function signoutfunc() {
  //   localStorage.removeItem("accno");
  //   axios
  //     .put("https://apollo.iterve.in/apollouser", {
  //       params: { accno: accno, deviceId: "" },
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   navigate("/login");
  // }

  function search(phone) {
    // console.log(accno);
    // setshowdata([]);
    if (!accno || !phone) return setshowdata([]);
    if (phone.length < 10) {
      setshowdata([]);
      return alert("please provide a valid 10 digit number");
    }
    axios
      .get("https://apollo.iterve.in/apollouser/fetchVitalsDataForNumber", {
        params: { accno: accno, phone: phone },
      })
      .then((res) => {
        if (res.data.length <= 0) {
          setshowdata([]);
          return alert("No vitals found for the given number");
        }
        setshowdata(res.data[0].vitalsData);
      });
  }

  function findObjectByPhoneNumber(phoneNumber, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i].phone === phoneNumber) {
        return jsonArray[i];
      }
    }
    return null;
  }

  function allusersadmin() {
    console.log("allusers", allapollousers, allendusers);
    for (let i = 0; i < allapollousers.length; i++) {
      if (
        allapollousers[i].accountNumber !== "a" &&
        allapollousers[i].accountNumber !== "s" &&
        allapollousers[i].accountNumber !== "demo"
      ) {
        for (let j = 0; j < allapollousers[i].vitalsData.length; j++) {
          allKnownData.push(allapollousers[i].vitalsData[j]);
        }
      }
    }
    for (let i = 0; i < allendusers.length; i++) {
      allKnownData.push(allendusers[i]);
    }
    // console.log(allKnownData);
    setshowdata(allKnownData);
    setdata(allKnownData);
  }

  useEffect(() => {
    if (!accno) return;
    axios
      .get("https://apollo.iterve.in/apollouser/vitalsDataSize", {
        params: { accno },
      })
      .then((res) => {
        // console.log(res.data)
        setDataCount(res.data.vitalsDataSize);
        if (accno !== "a" && accno !== "s" && accno !== "demo") {
          settotThatMember(res.data.vitalsDataSize);
        }
        setname(res.data.username);
      });
    if (accno && accno.startsWith("admin")) {
      allusersadmin();
    }
  }, [allapollousers, allendusers]);

  useEffect(() => {
    var a = 0;

    if (accno && accno.startsWith("admin")) {
      axios
        .get("https://apollo.iterve.in/apollouser/all")
        .then((res) => {
          console.log(res.data);

          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].accountNumber !== "a" &&
              res.data[i].accountNumber !== "s" &&
              res.data[i].accountNumber !== "demo"
            ) {
              a = a + res.data[i].vitalsData.length;
            }
          }
          setallapollousers(res.data);
          // console.log(a);
          setnoalldata(a);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("https://apollo.iterve.in/enduser/all")
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.length);
          settotenduser(res.data);
          setallendusers(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get("https://apollo.iterve.in/apollouser/allcount")
        .then((res) => setnoalldata(res.data.totalVitalsData));

      axios
        .get("https://apollo.iterve.in/enduser/allcount")
        .then((res) => {
          // console.log("enduserCount", res.data.cnt);
          settotenduser(res.data.cnt);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function convertDateFormat(dateString) {
    const parts = dateString.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    const date = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[date.getMonth()];
    const newDateFormat = monthName + " " + day + ", " + year;

    return newDateFormat;
  }

  const pdfContainerSize = () => {
    const dropId = document.getElementById("dropId");
    const pdfcontainer = document.getElementById("pdfcontainer");
    if (cont === false) {
      pdfcontainer.style.width = "90vw";
      dropId.style.transform = "scaleY(-1)";
    } else {
      pdfcontainer.style.width = "55vw";
      dropId.style.transform = "scaleY(1)";
    }
    console.log("cont", cont);
  };

  useEffect(pdfContainerSize, [cont]);
  const dropFunc = () => {
    setCont(!cont);
  };

  return (
    <div className={classes.container}>
      <Navbar />
      <div className={classes.heading}>
        <div className={classes.circle}></div>
        <div className={classes.texts}>
          <div className={classes.text1}>Welcome,</div>
          <div className={classes.text2}>{name}</div>
        </div>
        <img src={camera} className={classes.camera} />
        <div
          className={classes.newbutton}
          onClick={() => {
            navigate("/selftest", { state: "fromApolloMember" });
          }}
        >
          New Test
        </div>
      </div>
      <div className={classes.pdfhead}>
        <div className={classes.prevreports}>Previous Reports</div>
        {/* <img src={list} className={classes.listicon} />
        <img src={grid} className={classes.listicon} /> */}
        <div className={classes.search} ref={searchInputRef}>
          <div className={classes.searchbar}>
            {searched ? (
              <>
                <input
                  type="text"
                  onChange={(e) => setnumber(e.target.value)}
                  className={classes.inputsearch}
                  value={number}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      search(number);
                    }
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <img
              src={searchbutton}
              className={classes.listicon}
              onClick={() => {
                setsearched((val) => !val);
                search(number);
              }}
            />
          </div>
        </div>
        <div className={classes.assessmentHeadContainer} onClick={dropFunc}>
          <div className={classes.dropElement}>
            <div className={classes.assesmentText}>Assessment Counter</div>
            <img
              src={dropdownIcon}
              className={classes.dropdownIcon}
              id="dropId"
            />
          </div>
        </div>
      </div>
      {showdata ? (
        <div>
          <div className={classes.pdfAssessmentContainer}>
            <div className={classes.pdfcontainer} id="pdfcontainer">
              {dataCount > 0 && accno !== undefined && (
                <PdfIcons
                  data={data}
                  showdata={showdata}
                  navigate={navigate}
                  accno={accno}
                  dataCount={dataCount}
                />
              )}
            </div>
            {cont ? (
              <div className={classes.assessmentContainer}>
                <div className={classes.dropElement}>
                  <div className={classes.dropText}>Test Record by {name}:</div>
                  {accno.startsWith("admin") ? (
                    <div className={classes.countText}>-</div>
                  ) : (
                    <div className={classes.countText}>{totThatMember}</div>
                  )}
                </div>
                <div className={classes.dropElement}>
                  {accno.startsWith("admin") ? (
                    <div className={classes.dropText}>
                      Test Record by other 12 users:
                    </div>
                  ) : (
                    <div className={classes.dropText}>
                      Test Record by other 11 users:
                    </div>
                  )}
                  {accno.startsWith("admin") ? (
                    <div className={classes.countText}>-</div>
                  ) : (
                    <div className={classes.countText}>
                      {noalldata - totThatMember}
                    </div>
                  )}
                </div>
                <div className={classes.dropElement}>
                  <div className={classes.dropText}>
                    Test Record by End User:
                  </div>
                  {accno.startsWith("admin") ? (
                    <div className={classes.countText}>-</div>
                  ) : (
                    <div className={classes.countText}>{totenduser}</div>
                  )}
                </div>
                <div className={classes.dropElement}>
                  <div className={classes.dropText}>
                    Test Record by Apollo Teams:
                  </div>
                  <div className={classes.countText}>
                    {noalldata + totenduser}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.signoutSec}>
            <SignOut />
          </div>
        </div>
      ) : (
        <div className={classes.signoutSec}>
          <SignOut />
        </div>
      )}
    </div>
  );
};

export default Reports;
