import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./signOut.module.css";

const SignOut = () => {
  const accno = localStorage.getItem("accno");
  const navigate = useNavigate();

  function signoutfunc() {
    localStorage.removeItem("accno");
    axios
      .put("https://apollo.iterve.in/apollouser", {
        params: { accno: accno, deviceId: "" },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/login");
    window.location.reload();
  }
  return (
    <button onClick={signoutfunc} className={classes.signout}>
      SIGN OUT
    </button>
  );
};

export default SignOut;
