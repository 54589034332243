import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomeScreen from "./HomeScreen/HomeScreen";
import Login from "./Login/Login";
import Selftest from "./Selftest/Selftest";
import Register from "./Registration/Register";
import Vitals from "./Vitals/Vitals";
import Reports from "./Reports/Reports";
import RegisterUser from "./RegisterUser/RegisterUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Fingerprint2 from "fingerprintjs2";

function App() {
  const [did, setdid] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [loginInvoked, setLoginInvoked] = useState(false);
  const [tokenGen, setTokenGen] = useState(false);

  console.log("token gen", tokenGen);
  async function getuser(deviceId) {
    console.log(
      "getUser is called,\n userExistence:",
      userExists,
      "\ndevice Id:",
      deviceId
    );
    if (!deviceId || deviceId === "") return;
    await axios
      .get("https://apollo.iterve.in/apollouser/thisdid", {
        params: { did: deviceId },
      })
      .then((res) => {
        console.log(res.data, res.status);
        console.log("deviceId validated", performance.now() / 1000);
        if (res.status == 200) {
          console.log("user with device id found");
          localStorage.setItem("accno", res.data.accountNumber);
          setUserExists(true);
        } else {
          console.log("the device id does not corresponds to any user");
          setUserExists(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // Generate fingerprint
    Fingerprint2.get(async (components) => {
      const deviceId = await Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
      // if(loginInvoked)
      console.log("deviceId fetched", performance.now() / 1000);
      getuser(deviceId);
    });
  }, [did]);

  useEffect(() => {
    console.log("log in press caught through app js useEffect");
    // Generate fingerprint
    Fingerprint2.get(async (components) => {
      const deviceId = await Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
      // if(loginInvoked)
      console.log("deviceId fetched", performance.now() / 1000);
      getuser(deviceId);
    });
  }, [loginInvoked]);

  // useEffect (() => {
  //   console.log(did);
  //   if(did && localStorage.getItem('accno')) {
  //     const accno = localStorage.getItem('accno');
  //     // localStorage.removeItem('accno')

  //     axios.get("https://apollo.iterve.in/apollouser", { params: { accno } })
  //     .then((res) => {
  //       console.log(res.data);
  //       console.log(accno)
  //       console.log(res.data.deviceid, res.data,res.status);
  //       console.log(res.data.deviceid, did);
  //       if(res.data.deviceid !== did){
  //         setUserExists(false)
  //       }
  //       else{
  //         setUserExists(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //   }
  //   setUserExists(true);
  // },[]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={userExists ? <Navigate to="/reports" /> : <HomeScreen />}
        />
        <Route
          path="/login"
          element={<Login setLoginInvoke={setLoginInvoked} />}
        />
        <Route path="/selftest/:token" element={<Selftest />} />
        <Route
          path="/selftest"
          element={
            userExists ? (
              <Selftest />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/vitals" element={<Vitals />} />
        <Route path="/vitals/:pid/:cid" element={<Vitals />} />

        <Route
          path="/registeruser"
          element={<RegisterUser setTokenGen={setTokenGen} />}
        />

        <Route
          path="/reports"
          element={
            userExists ? (
              <Reports />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
